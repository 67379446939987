import { datadogRum } from '@datadog/browser-rum';
import { getDeviceType } from '@common/lib/jsBridge';

export function safeJSONParse(value, fallback = null) {
  try {
    return JSON.parse(value);
  } catch (error) {
    console.error('Error while parsing JSON:', error);
    return fallback;
  }
}

export function fallbackCopyToClipboard(text, successCallback) {
  try {
    // Fallback method using Textarea.
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    textArea.style.top = '-999999px';
    textArea.style.left = '-999999px';
    document.body.appendChild(textArea);
    // textArea.focus();

    const { isiOS } = getDeviceType();
    if (isiOS) {
      textArea.setSelectionRange(0, textArea.value.length);
    } else {
      textArea.select();
    }
    const successful = document.execCommand('copy');
    if (successful) {
      successCallback();
    } else {
      throw new Error('document.execCommand failed');
    }
    document.body.removeChild(textArea);
  } catch (error) {
    console.error('document.execCommand error: ', error);
    datadogRum.addError(error);
  }
}

/**
 * Copies the specified text to the clipboard.
 * @param {String} text The text to copy.
 * @param {Function} successCallback The callback to be called when the text is successfully copied.
 */
export async function copyTextToClipboard(text, successCallback) {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      successCallback();
    } else {
      fallbackCopyToClipboard(text, successCallback);
    }
  } catch (error) {
    console.info('Failed to copy text to clipboard: ', error);
    fallbackCopyToClipboard(text, successCallback);
  }
}

/**
 * Returns true if the current page is accessed from Jarvis.
 * @returns {boolean}
 */
export function ifReferFromJavars() {
  const referrerUrl = document.referrer && new URL(document.referrer);
  if (referrerUrl && referrerUrl.hostname.indexOf('jarvis.geo.azure') > -1) {
    return true;
  }
  return false;
}

export function getParamsFromUrl() {
  try {
    const urlObj = new URL(location.href);
    const params = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of urlObj.searchParams) {
      params[key] = value;
    }
    return params;
  } catch (error) {
    console.error('Error while getting params from URL:', error);
    return {};
  }
}
