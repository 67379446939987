// Env
export const getHostEnv = () => {
  const host = window.location.host;
  if (host.indexOf('pax-poi-webapp.grab.dev') >= 0) {
    return 'dev';
  } else if (host.indexOf('pax-poi-webapp.stg-myteksi.com') >= 0) {
    return 'stg';
  }
  return 'prd';
};

export const isPrd = () => getHostEnv() === 'prd';

export const isStg = () => getHostEnv() === 'stg';

export const isDev = () => !(isPrd() || isStg());

export const isDaxSandbox = () => !!window.isDaxSandbox;

export const isMock = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const mock = searchParams.get('mock');
  return mock >= 1;
};

export const passGatewayForlookup = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const mock = searchParams.get('passGatewayForlookup');
  return mock >= 1;
};

// stg: https://jarvis.geo.azure.stg-myteksi.com/codeless-portal/page?id=ENQM8jNgqcrL6WgCyw_U_iA==
// prd: https://jarvis.geo.azure.myteksi.net/codeless-portal/page?id=EcdR6wFAjtOJqWrkFbmdy3g==
// params:
// poiId
// source_app
// transportation_type

export const getMycUrl = locale => {
  let host = 'https://jarvis.geo.azure.myteksi.net';
  let id = 'EcdR6wFAjtOJqWrkFbmdy3g==';
  if (!isPrd()) {
    host = 'https://jarvis.geo.azure.stg-myteksi.com';
    id = 'ENQM8jNgqcrL6WgCyw_U_iA==';
  }
  return `${host}/codeless-portal/${locale}/page/${id}`;
};
