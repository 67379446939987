import i18n from 'i18n-js';
import networkErrorSvg from 'public/images/network-error.svg';
import responseErrorSvg from 'public/images/response-error.svg';

export const focusTypes = ['pickup and dropoff', 'pickup', 'dropoff'];

export const feedbackAnswer = {
  ACCEPT: 1,
  REJECT: 2,
};

export const feedbackCategory = {
  CLOSED_OR_MOVED: 'CLOSED_OR_MOVED',
  SUSPICIOUS_LATLNG: 'SUSPICIOUS_LATLNG',
  SUSPICIOUS_ADDRESS: 'SUSPICIOUS_ADDRESS',
  ALIAS: 'ALIAS',
  TRANSLATED_NAME: 'TRANSLATED_NAME',
};

export const savedPlaceLabelConfig = {
  HOME_TAG_PRESET: {
    en: 'Home',
    id: 'Rumah',
    ms: 'Rumah',
    zh: '家',
    vi: 'Nhà',
    th: 'บ้าน',
    my: 'အိမ်',
    km: 'ផ្ទះ',
  },
  WORK_TAG_PRESET: {
    en: 'Work',
    id: 'Tempat Kerja',
    ms: 'Tempat Kerja',
    zh: '工作',
    vi: 'Cơ quan',
    th: 'ที่ทำงาน',
    my: 'အလုပ်',
    km: 'កន្លែងធ្វើការ',
  },
};

export const errorConfig = () => ({
  networkError: {
    image: networkErrorSvg,
    imageWidth: 140,
    imageHeight: 140,
    text: 'No connection found',
    subText: 'Check your network connection and try again.',
  },
  responseError: {
    image: responseErrorSvg,
    text: i18n.t('geo_search_revamp_heading_couldnt_load_lookup_null'),
    subText: i18n.t('geo_search_revamp_body_another_go_lookup_null'),
  },
  noTokenError: {
    image: responseErrorSvg,
    text: 'Require AccessToken!',
    subText: '',
  },
  nonePoiIdError: {
    image: responseErrorSvg,
    text: i18n.t('geo_search_revamp_heading_couldnt_load_lookup_null'),
    subText: '',
    isHiddenTryButton: true,
  },
});

export const getFeedbackContext = category => {
  switch (category) {
    case feedbackCategory.CLOSED_OR_MOVED:
      return i18n.t(
        'geo_places_search_revamp_body_place_still_operational_dropoff',
      );
    case feedbackCategory.SUSPICIOUS_LATLNG:
      return i18n.t(
        'geo_places_search_revamp_body_marked_accurately_wrong_loc',
      );
    case feedbackCategory.SUSPICIOUS_ADDRESS:
      return i18n.t('geo_places_search_revamp_body_address_correct_wrong_add');
    case feedbackCategory.ALIAS:
      return i18n.t('geo_places_search_revamp_body_place_alias');
    case feedbackCategory.TRANSLATED_NAME:
      return i18n.t('geo_places_search_revamp_body_place_alias');
    default:
      return '';
  }
};

export const getMessageContext = type => {
  switch (type) {
    case 'success':
      return i18n.t('geo_name_validation_notif_thanks_for_keeping_uptodate');
    case 'error':
      return i18n.t('geo_places_search_revamp_toast_couldnt_save_edits');
    case 'warning':
      return '';
    default:
      return '';
  }
};
