export function set(key, val) {
  if (val) {
    sessionStorage.setItem(key, JSON.stringify(val));
  }
}

export function get(key) {
  try {
    return JSON.parse(sessionStorage.getItem(key));
  } catch (e) {
    return null;
  }
}

export function remove(key) {
  sessionStorage.removeItem(key);
}
